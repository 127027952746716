import React, { useMemo, useCallback } from 'react';

import AutoCompleteDropdown from 'components/uiLibrary/FormInputs/BaseComponents/AutoCompleteDropdown';
import useTracking from 'components/Globals/Analytics';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';

import queries from 'containers/Artist/queries';

import { useAppliedProfessionFilter } from 'utils/globals/ssrQueries/hooks';
import { TP } from 'constants/index';
import { PROFESSION_TYPES } from 'operabase-router/constants';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';

import classes from './ArtistProfessionDropdown.module.scss';

const PROFESSION_TYPES_COUNT = Object.keys(PROFESSION_TYPES).length;

const LABELS = {
  [PROFESSION_TYPES.VOICE_TYPE]: `${TP}.FILTER_VOICETYPES`,
  [PROFESSION_TYPES.INSTRUMENTALIST]: `${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`,
  [PROFESSION_TYPES.PROFESSION]: `${TP}.FILTER_PROFESSIONS`,
  [PROFESSION_TYPES.ROLE_REPERTOIRE]: `${TP}.FILTER_ROLE_REPERTOIRE`,
};

const getProfessionType = profession => {
  if (profession?.type) {
    if (profession?.type === PROFESSION_TYPES.VOICE_TYPE) {
      return PROFESSION_TYPES.VOICE_TYPE;
    }

    if (profession?.type === PROFESSION_TYPES.INSTRUMENTALIST) {
      return PROFESSION_TYPES.INSTRUMENTALIST;
    }
  }

  return PROFESSION_TYPES.PROFESSION;
};

export const PROFESSION_ROLE_TYPES = [
  PROFESSION_TYPES.VOICE_TYPE,
  PROFESSION_TYPES.PROFESSION,
  PROFESSION_TYPES.INSTRUMENTALIST,
  PROFESSION_TYPES.ROLE_REPERTOIRE,
];

const ArtistProfessionDropdown = ({
  types = PROFESSION_ROLE_TYPES,
  className,
  value,
  onChange,
  fullWidth,
  disablePortal,
  trackingData,
  isRoleRepertoire,
}) => {
  const track = useTracking();
  const { obRouteContext } = useAppContext();
  const { page } = obRouteContext;
  const { entityType } = page;
  const { navigate } = usePageContext();

  const { t } = useTranslation('NS_APP_GLOBALS');

  const professionsQuery = useCallback(
    props =>
      queries.getArtistProfessions({
        ...props,
        filters: {
          ...props.filters,
          is_searchable: true,
        },
        queryConfig: {
          ...props.queryConfig,
          select: response =>
            types.reduce((acc, type) => {
              acc.push(...(response?.data?.[type] || []));
              return acc;
            }, []),
        },
      }),
    [types],
  );

  const label = useMemo(() => {
    if (types.length < PROFESSION_TYPES_COUNT) {
      return types.map(type => t(LABELS[type])).join(' / ');
    }

    return t(LABELS[PROFESSION_TYPES.PROFESSION]);
  }, [t, types]);

  const selectedProfession = useAppliedProfessionFilter(value);
  const profession = useMemo(() => {
    if (types.length < PROFESSION_TYPES_COUNT) {
      const professionType = getProfessionType(selectedProfession);

      if (types.includes(professionType)) {
        return selectedProfession;
      }

      return null;
    }

    return selectedProfession;
  }, [types, selectedProfession]);

  const onChangeHandler = (e, newSelectedProfession) => {
    if (onChange) {
      onChange(e, newSelectedProfession);
    }
    track.click({
      ...trackingData,
      meta: {
        id: newSelectedProfession?.slug,
      },
    });
  };

  if (isRoleRepertoire) {
    return (
      <LinkButton
        variant="text"
        {...navigate.getLinkProps({
          entityType,
          pro: true,
          tab: null,
        })}
        styles={{ root: classes.filterItem }}
        isLink
        disableUnderline
      >
        <Typography variant="h2" size="12" className={classes.filterText}>
          {t(LABELS[PROFESSION_TYPES.ROLE_REPERTOIRE])}
        </Typography>
        <SpriteIcon name="arrow_drop_down" size="16" className={classes.arrowIcon} />
      </LinkButton>
    );
  }

  return (
    <AutoCompleteDropdown
      className={className}
      value={profession}
      onChange={onChangeHandler}
      searchQuery={professionsQuery}
      queryKey="aggregation_query"
      fullWidth={fullWidth}
      disablePortal={disablePortal}
      textFieldProps={{
        ...(!profession && {
          placeholder: label,
        }),
      }}
      prefetchSearchQuery
    />
  );
};

export default ArtistProfessionDropdown;
