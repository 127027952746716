/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import BannerLinkCTA, { BANNER_LINK_CTA_VARIANTS } from 'components/uiLibrary/BannerLinkCTA';

import usePageContext from 'utils/hooks/usePageContext';
import useTracking from 'components/Globals/Analytics';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import { useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import { useTranslation } from 'src/i18n';
import { TP, PROFILE_TYPES } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './ConversionFlowBanners.module.scss';

const BannerDetailsDrawer = dynamic(() => import('./BannerDetailsDrawer'));

export const TYPE = {
  CASTING: 'casting',
  ARTIST: 'artist',
  ORGANIZATION: 'organization',
};

const trackingData = {
  section: SECTIONS.CON_FLOW_BANNERS,
};

const Banner = ({ type }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [isOpen, setIsOpen] = useState(false);
  const track = useTracking();
  const { profileData, entityType } = useGetTargetProfile();
  const { ownerProfile } = useProfileAccesses();
  const { navigate, permissions } = usePageContext();
  const { isLoggedIn } = permissions;
  const isCastingBanner = type === TYPE.CASTING;

  const handleCheckPermission = useCallback(() => {
    if (!isLoggedIn) {
      setIsOpen(true);
      return;
    }

    if (isCastingBanner) {
      if (permissions?.castingToolPermissions?.hasAccess) {
        const linkProps = navigate.getLinkProps({
          baseRoute: ROUTE_RESERVED_KEYWORDS.casting,
          onlyLinkProps: true,
        });
        navigate.to(linkProps);
      } else {
        setIsOpen(true);
      }
    } else {
      if (entityType !== ENTITY_TYPES.PROFILE && profileData) {
        setIsOpen(true);
        return;
      }
      if ((profileData && entityType) || ownerProfile?.profile?.id) {
        const isPro = profileData?.subscriptionStatus === PROFILE_TYPES.PRO;

        if (isPro) {
          const linkProps = navigate.getLinkProps({
            entityType: ENTITY_TYPES.PROFILE,
            entity: profileData.id ? profileData : ownerProfile?.profile,
            onlyLinkProps: true,
            edit: true,
          });
          navigate.to(linkProps);
        } else {
          setIsOpen(true);
        }
      }
    }
  }, [
    isLoggedIn,
    isCastingBanner,
    permissions?.castingToolPermissions?.hasAccess,
    navigate,
    entityType,
    profileData,
    ownerProfile?.profile,
  ]);

  const handleOnClick = () => {
    track.click({
      ...trackingData,
      component: isCastingBanner ? COMPONENTS.CASTING_TOOL_BANNER : COMPONENTS.ARTIST_MANAGER_BANNER,
    });

    handleCheckPermission();
  };

  return (
    <>
      <BannerLinkCTA
        message={t(`${TP}.${isCastingBanner ? 'FN_CONVERSION_CASTING' : 'FN_CONVERSION_ARTIST'}`)}
        ctaLabel={t(`${TP}.FN_CONVERSION_TRY_NOW`)}
        onClick={handleOnClick}
        variant={isCastingBanner ? BANNER_LINK_CTA_VARIANTS.ORANGE : BANNER_LINK_CTA_VARIANTS.BLUE}
      />
      {isOpen && <BannerDetailsDrawer isOpen={isOpen} type={type} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const ConversionFlowBanners = ({ isInline = false, showCasting = true, showArtist = true }) => {
  const banners = [];

  if (showCasting) {
    banners.push(<Banner key="casting" type={TYPE.CASTING} />);
  }

  if (showArtist) {
    banners.push(<Banner key="artist" type={TYPE.ARTIST} />);
  }

  if (banners.length === 0) {
    return null;
  }

  if (banners.length === 1) {
    return banners[0];
  }

  return <div className={classnames(classes.cfb, { [classes.cfbInline]: isInline })}>{banners}</div>;
};

export default ConversionFlowBanners;
