// TODO: FB-25982 Remove separate component for this, add to home page itself
import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';

import ConversionFlowBanners from 'components/Globals/Banners/ConversionFlowBanners';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';
import { SECTIONS } from 'components/Globals/Analytics/constants';

import PerformanceSearch from './PerformanceSearch';

import classes from './ProductionPerformanceSearch.module.scss';

const Modal = dynamic(() => import('components/uiLibrary/Modal'));

const trackingData = {
  section: SECTIONS.PRODUCTIONS_AND_PERFORMANCES_SEARCH,
};

const ProductionPerformanceSearch = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const [isOpen, setIsOpen] = useState(false);

  if (isMobile) {
    return (
      <div className={classes.performanceSearchMobile}>
        <Typography variant="p" className={classes.performanceSearchMobile__title} weight="bold" size={32}>
          {t(`${TP}.FN_PERFORMANCES`)}
        </Typography>
        <LinkButton
          variant="secondary"
          rightIcon={<SpriteIcon icon="search" size={18} className={classes.performanceSearchMobile__icon} />}
          styles={{ root: classes.performanceSearchMobile__btn }}
          onClick={() => setIsOpen(true)}
          trackingData={trackingData}
        >
          <Typography color="secondary" truncate>
            {t(`${TP}.FN_SEARCH_PERFORMANCES_FROM`, { performanceEndYear: '2026' })}
            {/* TODO: FB-25982 Hard coded year value */}
          </Typography>
        </LinkButton>

        <div className={classes.conFlowBanners}>
          <ConversionFlowBanners />
        </div>

        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          styles={{
            modalContainer: classes.modal__container,
          }}
          title={t(`${TP}.FN_SEARCH_PRODUCTION_PERFORMANCES`)}
          allowMobileDrawer
          className={classes.modal}
        >
          <PerformanceSearch
            isClearText
            activeTab={null}
            styles={{
              actions: classes.actions,
              searchBtn: classes.searchBtn,
              clearBtn: classes.clearBtn,
              wrapper: classes.wrapperMobile,
            }}
            showConcludingBlock={false}
            trackingData={trackingData}
          />
        </Modal>
      </div>
    );
  }

  return (
    <>
      <Typography variant="p" className={classes.title} weight="bold" size={32}>
        {t(`${TP}.PRODUCTIONS_AND_PERFORMANCES`)}
      </Typography>
      <PerformanceSearch
        isClearText
        styles={{
          actions: classes.actions,
          searchBtn: classes.searchBtn,
          clearBtn: classes.clearBtn,
          wrapper: classes.wrapper,
        }}
        trackingData={trackingData}
      />
    </>
  );
};

export default ProductionPerformanceSearch;
