import React, { useMemo } from 'react';
import { getCurrentHub as SentryGetCurrentHub, configureScope as SentryConfigureScope } from '@sentry/nextjs';
import dynamic from 'next/dynamic';

import Loader from 'components/uiLibrary/Loader';
import ErrorPage from 'containers/ErrorPage';

import { URL_STATIC_PATHS, RESPONSE_STATUS_CODES, ENTITY_MAIN_TABS } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import getServerProps from 'utils/globals/ssrQueries';
import usePageContext from 'utils/hooks/usePageContext';
import withAuthCheck from 'utils/hocs/withAuthCheck';
import useTracking from 'components/Globals/Analytics';
import PageLayout from 'components/Globals/Layout/PageLayout';
import { getSentryTransactionName } from 'utils/globals/sentry';
import { PAGE_CATEGORY, PAGE_TYPES } from 'components/Globals/Analytics/constants';

import { getPageTranslations } from 'src/i18n';
import orgReducer, { key as orgKey } from 'containers/Organizations/reducer';
import artistReducer, { key as artistKey } from 'containers/Artist/reducer';
import addRepertoireReducer, { key as addRepertoireKey } from 'containers/AddRepertoire/reducer';
import editImagesReducer, { key as editImagesKey } from 'containers/EditImages/reducer';
import validationReducer, { key as validationKey } from 'containers/Validation/reducer';
import { useInjectReducer } from 'store/injectReducer';
import IntermediatePopupProvider from 'utils/hooks/useIntermediatePopup';
import useAppContext from 'utils/hooks/useAppContext';

/* TODO: FB-32983
  Hack for dynamic import CSS missing issue - with assumption that users will be navigating to home of that entity
  Remove this once next js solves for this issue https://github.com/vercel/next.js/issues/33286
*/
import Home from 'components/Home';

const ProductionListing = dynamic(() => import('components/Productions/Common/ProductionListing'));
const BaseWrapper = dynamic(() => import('components/Globals/Layout/StubPage/BaseWrapper'));

const OrganizationEdit = dynamic(() => import('components/Organization/Edit'));
const ArtistEdit = dynamic(() => import('components/Artist/Edit'));
const ModifyProduction = dynamic(() => import('components/Productions/Edit/ModifyProduction'));
const SearchProductions = dynamic(() => import('components/Productions/Edit/SearchProductions'));
const ReviveProduction = dynamic(() => import('components/Productions/Edit/ReviveProduction'));
const ValidateProduction = dynamic(() => import('components/Productions/Edit/ValidateProduction'));

const PhotoGallery = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/PhotoGallery'));
const ReviewsPage = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/ReviewsPage'));
const VideoGallery = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/VideoGallery'));
const ProductionHome = dynamic(() => import('components/Productions/Display/Home'));
const ProductionCastCrew = dynamic(() => import('components/Productions/Display/ProductionCastCrew'));
const ProductionAboutPage = dynamic(() => import('components/Productions/Display/ProductionAboutPage'));
const ProductionPerformancesPage = dynamic(() => import('components/Productions/Display/ProductionPerformancesPage'));

const EditPages = withAuthCheck(() => {
  useInjectReducer([
    { key: orgKey, reducer: orgReducer },
    { key: artistKey, reducer: artistReducer },
    { key: addRepertoireKey, reducer: addRepertoireReducer },
    { key: editImagesKey, reducer: editImagesReducer },
    { key: validationKey, reducer: validationReducer },
  ]);
  const { entityType, mainPath, entity, isLoading } = usePageContext();

  if (isLoading) {
    return <Loader />;
  }

  if (!entity?.id) {
    return <ErrorPage statusCode={404} />;
  }

  if (mainPath) {
    switch (mainPath) {
      case URL_STATIC_PATHS.MODIFY_PRODUCTION: {
        return <ModifyProduction />;
      }
      case URL_STATIC_PATHS.REVIVALS: {
        return <ReviveProduction />;
      }
      case URL_STATIC_PATHS.SEARCH_PRODUCTIONS: {
        return <SearchProductions />;
      }
      case URL_STATIC_PATHS.VALIDATION: {
        return <ValidateProduction />;
      }
      default: {
        return <ErrorPage statusCode={403} />;
      }
    }
  }

  if (entityType === ENTITY_TYPES.ORGANIZATION) {
    return <OrganizationEdit />;
  }

  if (entityType === ENTITY_TYPES.PROFILE) {
    return (
      <IntermediatePopupProvider>
        <ArtistEdit />
      </IntermediatePopupProvider>
    );
  }

  return <ErrorPage statusCode={403} />;
});

const EntityStubPages = props => {
  const { obRouteContext } = useAppContext();
  const { entity } = usePageContext();
  const { mainPath, subPath, page } = obRouteContext || {};
  const { entityType } = page || {};

  const TabComponent = useMemo(() => {
    if (entityType !== ENTITY_TYPES.PRODUCTION) {
      return BaseWrapper;
    }

    switch (mainPath) {
      case ENTITY_MAIN_TABS.VIDEOS:
        return VideoGallery;
      case ENTITY_MAIN_TABS.IMAGES:
        return PhotoGallery;
      case ENTITY_MAIN_TABS.REVIEWS:
        return ReviewsPage;
      case ENTITY_MAIN_TABS.PERFORMANCES:
        return ProductionPerformancesPage;
      case ENTITY_MAIN_TABS.ABOUT:
        return ProductionAboutPage;
      case ENTITY_MAIN_TABS.CAST_CREW:
        return ProductionCastCrew;
      case ENTITY_MAIN_TABS.HOME:
      default:
        return ProductionHome;
    }
  }, [mainPath, entityType]);

  return (
    <PageLayout>
      {TabComponent && (
        <TabComponent mainPath={mainPath} subPath={subPath} entityType={entityType} entity={entity} {...props} />
      )}
    </PageLayout>
  );
};

const RootPage = ({ statusCode, ...rest }) => {
  const { obRouteContext } = useAppContext();
  const { basePath, entityType, entityId, entity, mainPath, subPath, edit, paths } = usePageContext();
  const track = useTracking();

  // useEffect(() => {
  //   const sentryTransactionName = getSentryTransactionName({
  //     basePath,
  //     entityType,
  //     entityId,
  //     mainPath,
  //     subPath,
  //     edit,
  //     paths,
  //   });
  //   const transaction = SentryGetCurrentHub()
  //     ?.getScope()
  //     ?.getTransaction();

  //   if (transaction && sentryTransactionName) {
  //     transaction.setName(sentryTransactionName);
  //   }
  // }, [basePath, entityType, entityId, mainPath, subPath, edit, paths]);

  // useEffect(() => {
  //   if (entityType) {
  //     if (entity) {
  //       if (entityType === ENTITY_TYPES.PROFILE) {
  //         track.pageView({ pageCategory: PAGE_CATEGORY.STUB, pageType: PAGE_TYPES.ARTIST });
  //       } else if (entityType === ENTITY_TYPES.ORGANIZATION) {
  //         track.pageView({ pageCategory: PAGE_CATEGORY.STUB, pageType: entity?.organizationType?.slug || '' });
  //       }
  //     }
  //   } else {
  //     track.pageView({ pageCategory: PAGE_CATEGORY.LANDING, pageType: PAGE_TYPES.HOME });
  //   }
  // }, [track, entityType, entity]);

  const { status, page } = obRouteContext || {};

  if (status !== RESPONSE_STATUS_CODES.SUCCESS) {
    return <ErrorPage statusCode={status} />;
  }

  if (page?.entity?.id) {
    if (page?.edit) {
      return <EditPages />;
    }

    return <EntityStubPages statusCode={statusCode} {...rest} />;
  }

  if (Object.keys(obRouteContext.linkProps.filters).length > 0 || page?.entityType === ENTITY_TYPES.PRODUCTION) {
    return (
      <PageLayout>
        <ProductionListing hideFilters hideActions />
      </PageLayout>
    );
  }

  return <Home />;
};

RootPage.i18nNamespaces = getPageTranslations([
  'NS_ENTITY_STUB_PAGE',
  'NS_CONVERSION_FLOW',
  'NS_INSIGHTS',
  'EDIT_MODE_SUBSCRIPTION_NUDGE',
  'NS_EDIT',
  'NS_REGISTRATION_V4',
]);

export async function getServerSideProps(ctx) {
  const { redirect, context: _context, ...serverProps } = await getServerProps(ctx);

  if (redirect?.destination) {
    ctx.res.writeHead(redirect?.statusCode, { location: redirect?.destination });
    ctx.res.end();
  }

  // SentryConfigureScope(scope => {
  //   scope.setTransactionName(getSentryTransactionName(context));
  // });

  return serverProps;
}

export default RootPage;
