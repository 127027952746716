import React, { useEffect, useMemo } from 'react';

import usePageContext from 'utils/hooks/usePageContext';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';

const withAuthCheck = Component => ({ ...props }) => {
  const { navigate, pageURL, permissions } = usePageContext();
  const isLoggedIn = useMemo(() => permissions?.isLoggedIn, [permissions]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate.to(navigate.getLinkProps({ baseRoute: ROUTE_RESERVED_KEYWORDS.login, queryParams: { page: pageURL } }), true);
    }
  }, [navigate, pageURL, isLoggedIn]);

  if (isLoggedIn) {
    return <Component {...props} />;
  }

  return null;
};

export default withAuthCheck;
