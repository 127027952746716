import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';

import ArtistProfessionDropdown, {
  PROFESSION_ROLE_TYPES,
} from 'components/Globals/FormFields/Display/ArtistProfessionDropdown';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import Typography from 'components/uiLibrary/Typography';

import queries from 'containers/Artist/queries';

import { FILTER_TYPES, PROFESSION_TYPES, ENTITY_TYPES } from 'operabase-router/constants';
import { SECTIONS, COMPONENTS, CATEGORIES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { useQuery } from 'utils/react-query';
import LinkButton from 'components/uiLibrary/LinkButton';
import classes from './IndexPageFilters.module.scss';

const artistProfessionTrackingData = {
  section: SECTIONS.ARTIST_LIST,
  component: COMPONENTS.FILTERS,
  category: CATEGORIES.SEARCH,
};

const IndexPageFilters = ({ concise = false, sticky }) => {
  const { filters, applyFilters } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();

  const { data: professionsAndRoles } = useQuery(
    queries.getArtistProfessions({
      limit: 20,
      queryConfig: {
        select: response =>
          PROFESSION_ROLE_TYPES.reduce((acc, type) => {
            acc.push(...(response?.data?.[type]?.slice(0, 3) || []));
            return acc;
          }, []),
      },
    }),
  );

  const onSelectProfession = useCallback(
    (e, profession) => {
      const professionFilterKey = FILTER_TYPES.PROFESSION;
      const pageFilterKey = FILTER_TYPES.PAGE;
      const pageFilterCurrentValue = filters?.[pageFilterKey];
      const finalFiltersExcludingPage = omit(filters, [professionFilterKey, pageFilterKey]);

      if (profession) {
        finalFiltersExcludingPage[FILTER_TYPES.PROFESSION] = [profession];
      }

      applyFilters({
        entityType: ENTITY_TYPES.PROFILE,
        filters: {
          ...finalFiltersExcludingPage,
          ...(pageFilterCurrentValue && Object.keys(finalFiltersExcludingPage).length > 0
            ? { [pageFilterKey]: 1 }
            : {}),
        },
      });
    },
    [applyFilters, filters],
  );

  const selectedProfession = useMemo(() => filters?.[FILTER_TYPES.PROFESSION]?.[0]?.entity, [filters]);

  const professionFields = useMemo(() => {
    if (concise) {
      return [{ fullWidth: true }];
    }

    return [
      {
        types: [PROFESSION_TYPES.VOICE_TYPE],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.VOICE_TYPES },
      },
      {
        types: [PROFESSION_TYPES.INSTRUMENTALIST],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.INSTRUMENTALISTS },
      },
      {
        types: [PROFESSION_TYPES.PROFESSION],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.PROFESSIONS },
      },
      {
        types: [PROFESSION_TYPES.ROLE_REPERTOIRE],
        trackingData: { ...artistProfessionTrackingData, subComponent: SUB_COMPONENTS.VOICE_TYPES },
        isRoleRepertoire: true,
      },
    ];
  }, [concise]);

  const Wrapper = sticky ? HorizontalScroller : 'div';
  const ProfessionListWrapper = sticky && !isDesktop ? 'div' : HorizontalScroller;

  return (
    <Wrapper outset>
      <div className={classnames(classes.root, { [classes.concise]: concise, [classes.root__sticky]: sticky })}>
        {!concise && (
          <div className={classnames(classes.filterContainer, { [classes.filterContainer__sticky]: sticky })}>
            {professionFields?.map((fieldProps, index) => (
              <ArtistProfessionDropdown
                key={index}
                className={classes.professionDropdown}
                value={selectedProfession}
                onChange={onSelectProfession}
                {...fieldProps}
              />
            ))}
          </div>
        )}
        {professionsAndRoles?.length > 0 && (
          <div className={classnames(classes.chipContainer, { [classes.chipContainer__sticky]: sticky })}>
            <div className={classes.separator}>|</div>
            <ProfessionListWrapper outset>
              <div className={classes.scrollableContent}>
                {professionsAndRoles.map((item, index) => (
                  <LinkButton
                    variant="text"
                    key={`${item?.id}-${index}`}
                    styles={{
                      root: classnames(classes.chipItem, {
                        [classes.chipItem__selected]: selectedProfession?.id === item.id,
                      }),
                    }}
                    onClick={e => onSelectProfession(e, item)}
                    preventDefault
                    disableUnderline
                  >
                    <Typography variant="h2" size="11" className={classes.chipText}>
                      {item?.name}
                    </Typography>
                  </LinkButton>
                ))}
              </div>
            </ProfessionListWrapper>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default IndexPageFilters;
