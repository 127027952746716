import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';

import Switch, { SWITCH_TYPES } from 'components/uiLibrary/Switch';
import InfiniteListPage, { PageLoading } from 'components/Globals/Layout/InfiniteListPage';
import ProductionSlug from 'components/Productions/Display/Slugs/ProductionSlug';
import useQuickView, { QUICK_VIEW_TYPES } from 'components/Globals/Layout/QuickView';
import NoResult from 'components/Globals/NoResult';

import queries from 'containers/Productions/queries';

import { getCityCountry } from 'utils/globals';
import { createDate } from 'utils/date';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { TP, SORT_OPTION_VALUES, DATE_FORMATS } from 'constants/index';
import { getRedMaskStatus } from 'utils/common';
import classes from './ProductionListingDeprecated.module.scss';

export const VIEW_TYPES = {
  PERFORMANCE: 'PERFORMANCE',
  SEASON: 'SEASON',
};

export const ProductionViewModeSwitch = ({ viewMode, onChange, trackingData }) => (
  <Switch
    ns="NS_ENTITY_STUB_PAGE"
    type={SWITCH_TYPES.THIS_OR_THAT}
    leftOption={{
      value: VIEW_TYPES.PERFORMANCE,
      label: `${TP}.FN_DAY_VIEW`,
    }}
    rightOption={{
      value: VIEW_TYPES.SEASON,
      label: `${TP}.FN_SEASON_VIEW`,
    }}
    value={viewMode}
    onChange={({ value }) => onChange(value)}
    trackingData={trackingData}
  />
);

const SlugListing = ({
  entityType,
  entity,
  upcoming,
  data,
  openQuickPreview,
  renderCustomSlug,
  withMedia,
  trackingData,
}) => {
  const { mainPath } = usePageContext();
  return (
    <>
      {data?.map((production, index) => {
        if (renderCustomSlug) {
          return renderCustomSlug({
            production,
            entityType,
            entity,
            upcoming,
          });
        }

        return (
          <ProductionSlug
            key={`${production?.id}-${index}`}
            entity={entity}
            entityType={entityType}
            data={production}
            onClick={openQuickPreview}
            upcoming={upcoming}
            className={classnames(classes.productionSlug, { [classes.productionSlugOverview]: !mainPath && !upcoming })}
            withMedia={withMedia}
            trackingData={trackingData}
          />
        );
      })}
    </>
  );
};

const PerformanceViewListing = ({ entityType, entity, pages, upcoming, openQuickPreview, withMedia, trackingData }) => {
  const groupedProductions = useMemo(() => {
    const grouped = pages?.reduce((acc, page) => {
      const { data } = page;

      (data || []).forEach(performance => {
        const lastProductionId = acc?.[acc?.length - 1]?.id;
        const productionId = performance?.production?.id;
        const date = performance?.startDate;
        const prodTitle = performance?.metaInfo?.title;
        const venueName = performance?.venues?.[0]?.name;
        let title = '';
        if (prodTitle) {
          title += `${prodTitle}`;
        }
        if (date) {
          title += `, ${createDate(date).format(DATE_FORMATS.DAY_SHORT_MONTH_YEAR)}`;
        }
        if (venueName) {
          title += `, ${venueName}`;
        }

        const performanceDate = {
          id: performance?.id,
          date,
          time: performance?.startTime,
          location: getCityCountry(performance?.venue),
          boxOfficeWebsite: performance?.boxOfficeWebsite,
          isWorldPremiere: performance?.isWorldPremiere,
          isPremiere: performance?.isPremiere,
          isMatinee: performance?.isMatinee,
          isTour: performance?.isTour,
          title,
          venue: {
            name: performance?.venue?.name,
            id: performance?.venue?.id,
          },
        };

        if (lastProductionId === productionId) {
          acc[acc.length - 1].dates.push(performanceDate);
        } else {
          acc.push({
            id: performance?.production?.id,
            name: performance?.production?.name,
            slug: performance?.production?.slug,
            redMaskStatus: getRedMaskStatus({ production: performance?.production, entity }),
            isProgramNotAnnounced: performance?.production?.isProgramNotAnnounced,
            productionWorks: performance?.productionWorks,
            contributions: performance?.contributions,
            stats: performance?.stats,
            isCancelled: performance?.isCancelled,
            isArchived: performance?.isArchived,
            isNewProduction: performance?.production?.isNewProduction,
            upcomingDates: performance?.production?.upComingDates,
            productionDates: performance?.production?.productionDates,
            dates: [performanceDate],
          });
        }
      });

      return acc;
    }, []);

    return grouped;
  }, [pages]);

  return (
    <SlugListing
      upcoming={upcoming}
      entityType={entityType}
      entity={entity}
      data={groupedProductions}
      openQuickPreview={openQuickPreview}
      withMedia={withMedia}
      trackingData={trackingData}
    />
  );
};

const SeasonViewListing = ({
  pages,
  upcoming,
  entityType,
  entity,
  openQuickPreview,
  renderCustomSlug,
  withMedia,
  trackingData,
}) => (
  <>
    {pages?.map(({ data }, index) => (
      <SlugListing
        key={index}
        upcoming={upcoming}
        entityType={entityType}
        entity={entity}
        data={data}
        openQuickPreview={openQuickPreview}
        renderCustomSlug={renderCustomSlug}
        withMedia={withMedia}
        trackingData={trackingData}
      />
    ))}
  </>
);

const ListWrapper = ({
  viewMode,
  entityType,
  entity,
  query,
  openQuickPreview,
  infinite,
  upcoming,
  renderCustomSlug,
  withMedia,
  trackingData,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  return (
    <InfiniteListPage query={query} disabled={!infinite} disableQueryUpdate updateAppliedFilters={infinite}>
      {({ pages, count, isLoading }) => (
        <>
          <>
            {!isLoading && count === 0 ? (
              <NoResult
                title={t(`${TP}.FN_ARTIST_PRO_SEARCH_NO_RESULT`)}
                subtitle={t(`${TP}.FN_ARTIST_PRO_SEARCH_NO_RESULT_SUBTITLE`)}
              />
            ) : (
              <>
                {viewMode === VIEW_TYPES.SEASON ? (
                  <SeasonViewListing
                    upcoming={upcoming}
                    entityType={entityType}
                    entity={entity}
                    pages={pages}
                    openQuickPreview={openQuickPreview}
                    renderCustomSlug={renderCustomSlug}
                    withMedia={withMedia}
                    trackingData={trackingData}
                  />
                ) : (
                  <PerformanceViewListing
                    upcoming={upcoming}
                    entityType={entityType}
                    entity={entity}
                    pages={pages}
                    openQuickPreview={openQuickPreview}
                    renderCustomSlug={renderCustomSlug}
                    withMedia={withMedia}
                    trackingData={trackingData}
                  />
                )}
              </>
            )}
          </>
          <PageLoading defaultLoader />
        </>
      )}
    </InfiniteListPage>
  );
};

const ProductionListingDeprecated = ({
  className,
  entityType,
  entity,
  filters = {},
  viewMode = VIEW_TYPES.PERFORMANCE,
  infinite = true,
  upcoming = false,
  withMedia = false,
  limit = 10,
  renderCustomSlug,
  trackingData,
}) => {
  const { showQuickView } = useQuickView();

  const openQuickPreview = useCallback(
    ({ productionId }) => {
      showQuickView({
        type: QUICK_VIEW_TYPES.PRODUCTION,
        data: {
          entityId: productionId,
          quickViewContextId: entity?.id,
          isSeasonView: viewMode === VIEW_TYPES.SEASON,
        },
      });

      // NOTE: showQuickView is not required as depedency for this hook
    },
    [entity?.id, viewMode],
  );

  const query = useMemo(() => {
    const queryFilters = {
      ...(entityType && { entityType }),
      ...(entity?.id && { entityId: entity?.id }),
      limit,
      filters: {
        sort: viewMode === VIEW_TYPES.SEASON ? SORT_OPTION_VALUES.YEAR_DESC : SORT_OPTION_VALUES.YEAR_ASC,
        ...filters,
      },
      ...(withMedia && {
        mediaLimit: 5,
      }),
    };

    if (viewMode === VIEW_TYPES.SEASON) {
      return queries.getProductions(queryFilters);
    }

    return queries.getPerformances(queryFilters);
  }, [viewMode, entityType, entity?.id, limit, filters, withMedia]);

  return (
    <div className={className}>
      <ListWrapper
        viewMode={viewMode}
        entityType={entityType}
        entity={entity}
        query={query}
        infinite={infinite}
        upcoming={upcoming}
        openQuickPreview={openQuickPreview}
        renderCustomSlug={renderCustomSlug}
        withMedia={withMedia}
        trackingData={trackingData}
      />
    </div>
  );
};

export default ProductionListingDeprecated;
