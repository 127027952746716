import React, { useMemo, useCallback } from 'react';

import EntityTrendingList from 'components/Globals/EntityTrendingList';
import AdvertisementRenderer from 'components/Globals/AdvertisementBlock/AdvertisementRenderer';
import ConversionFlowBanners from 'components/Globals/Banners/ConversionFlowBanners';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import { SIZE } from 'components/Globals/PreviewCard';

import { ENTITY_TYPES, FILTER_TYPES } from 'operabase-router/constants';

import { useQuery } from 'utils/react-query';
import { getAdvertisementConfigs, AD_IDENTIFIER_TYPE, AD_PLACEMENTS, AD_TAGS } from 'utils/advertisements';

import queries from 'containers/Artist/queries';

import useAppContext from 'utils/hooks/useAppContext';

const trackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.ARTIST_CARD,
};

const seeAllTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.SEE_ALL_CTA,
};

const TrendingArtistProfessionSwimlane = ({ limit = 98, getFooterAd }) => {
  const { obRouteContext } = useAppContext();
  const { linkProps, filterParams } = obRouteContext;
  const { filters } = linkProps;
  const query = filters?.[FILTER_TYPES.PAGE] || filters?.[FILTER_TYPES.LETTER];
  const { data, isLoading } = useQuery(
    queries.getTrendingArtist({
      filters: filterParams,
      limit,
      queryConfig: {
        enabled: !Object.keys(filters)?.length,
      },
    }),
  );

  const artists = useMemo(() => {
    if (!data?.data?.length) {
      return [];
    }

    const list = data?.data;
    return list?.reduce((chunks, _, i) => {
      if (i % 10 === 0) {
        chunks.push(list?.slice(i, i + 10));
      }
      return chunks;
    }, []);
  }, [data]);

  if (!artists?.length) {
    return null;
  }

  return artists?.map((item, index) => (
    <EntityTrendingList
      entities={item}
      entityType={ENTITY_TYPES.PROFILE}
      entityTrackingData={{
        ...trackingData,
        searchQuery: query,
      }}
      seeAllTrackingData={seeAllTrackingData}
      isLoading={isLoading}
      footerContent={getFooterAd({ index, count: item?.length })}
      size={SIZE.SMALL}
    />
  ));
};

const TrendingArtistProfessions = () => {
  const adConfigs = useMemo(
    () =>
      getAdvertisementConfigs({
        identifiers: [AD_TAGS.ARTIST, AD_TAGS.CASTING],
        identifierType: AD_IDENTIFIER_TYPE.TAG,
        placement: AD_PLACEMENTS.INTERSTITIAL,
      }),
    [],
  );

  const getFooterAd = useCallback(
    ({ index, count }) => {
      if (index === 0) {
        return <ConversionFlowBanners />;
      }

      if (index % 3 === 0) {
        return (
          <AdvertisementRenderer
            config={adConfigs[(count + index) % adConfigs.length]}
            section={trackingData.section}
          />
        );
      }

      return null;
    },
    [adConfigs],
  );

  return <TrendingArtistProfessionSwimlane getFooterAd={getFooterAd} />;
};

export default TrendingArtistProfessions;
